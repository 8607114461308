@import "~@sravni/design-system-theme/lib/scss/core/variables";

.pageBlock {
 background-color: var(--color-L100);
}

$minMargin: 30px;
$maxContentWidth: 1260px;
$breakpoint-desktop: 1024px;

.content  {
  display: grid;
  gap: 24px;
  padding-top: 32px;
  margin: 0 16px 0 16px;

  @media (min-width: #{$breakpoint-desktop}) {
    max-width: $maxContentWidth;
    grid-template-columns: 3fr 1fr;

    @media (min-width: calc(#{$maxContentWidth} + #{$minMargin} * 2)) {
      grid-template-columns: 3fr 1fr;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.rightColumn {
  flex-shrink: 0;
  width: 300px;
  min-height: 100%;
  padding-bottom: 67px;
}

.stickyBlock {
  position: sticky;
  top: 24px;
}
