@import "~@sravni/design-system-theme/lib/scss/core/variables";
@import "~@sravni/ugc/lib/theme/typography.scss";
// Custom google search results

.search-results {
  // search results container
  & .gsc-control-cse {
    border-radius: 0 0 20px 20px;
    padding: 0 20px 20px;

    @media screen and (min-width: $breakpoint-desktop) {
      padding: 20px;
    }

  }

  // search results inner container
  & .gsc-results {
    width: 100%;
  }

  // search list item
  & .gsc-webResult.gsc-result, .gsc-webResult.gsc-result:hover {
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid var(--color-D10);
  }

  // search results found for n sec
  & .gsc-result-info {
    @extend .typography-body-m;
    color: var(--color-D30);
    padding-bottom: 14px;
    padding-left: 0;
    padding-right: 0;
  }

  // search word (word looking for)
  & .gs-webResult.gs-result a.gs-title:link b {
    color: var(--color-B100);
  }

  // remove padding from header and body of the search item
  & .gsc-thumbnail-inside, .gsc-table-result {
    padding: 0;
  }

  // result list item header
  & .gs-webResult.gs-result a.gs-title:link {
    @extend .typography-h5;
  }

  // spacer between header and body
  & .gsc-url-top {
    height: 8px;
  }

  // result list item body
  & .gs-webResult:not(.gs-no-results-result):not(.gs-error-result) .gs-snippet {
    @extend .typography-body-m;
    color: var(--color-D30)
  }

  // search word in result list item body
  & .gs-webResult:not(.gs-no-results-result):not(.gs-error-result) .gs-snippet b {
    color: var(--color-B100);
  }

  // pagination container
  & .gsc-results .gsc-cursor-box {
    margin: 20px 0 0;
  }

  // pagination inner container
  & .gsc-results .gsc-cursor {
    @extend .typography-subtitle-xl;
    color: var(--color-D60);
    display: flex;
    justify-content: center;
  }

  // pagination item
  & .gsc-results .gsc-cursor-box .gsc-cursor-page {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    margin: 0;
  }

  // pagination item hover
  & .gsc-results .gsc-cursor-box .gsc-cursor-page:hover {
    color: var(--color-B100);
  }

  // pagination item (current page)
  & .gsc-results .gsc-cursor-box .gsc-cursor-page.gsc-cursor-current-page {
    background: var(--color-B06);
    border-radius: 4px;
    color: var(--color-D100);
    text-decoration: none;
    padding: 4px 10px;
  }

  // ON RESIZE
  // branding container
  & .gcsc-more-maybe-branding-root {
    margin-top: 28px;
    display: flex;
    flex-direction: column;

  }

  // find more in google
  & .gcsc-find-more-on-google, {
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      fill: #636f7d;
    }
  }

  // find more in google inner text
  & .gcsc-find-more-on-google-query, .gcsc-find-more-on-google-text {
    @extend .typography-subtitle-m;
    color: #636f7d;
  }

  // google technologies
  & .gcsc-find-more-on-google-branding {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }

  // ON MOBILE NATIVE
  // find more on google
  & .gcsc-more-maybe-branding-box {
    border: none;
    margin-top: 28px;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  // google technologies
  & .gcsc-branding {
    margin-top: 24px;
  }

  @media screen and (min-width: $breakpoint-desktop) {

    & .gsc-control-cse {
      padding: 0 40px 48px;
    }

    & .gsc-webResult.gsc-result, .gsc-webResult.gsc-result:hover {
      padding-top: 28px;
      padding-bottom: 28px;
    }

    & .gsc-result-info {
      padding-top: 0;
      padding-bottom: 22px;
    }

    & .gsc-results .gsc-cursor-box {
      margin-top: 28px;
    }

    & .gsc-results .gsc-cursor {
      justify-content: flex-start;
    }

    & .gsc-results .gsc-cursor-box .gsc-cursor-page {
      width: 44px;
      height: 44px;
      margin-right: 8px;
    }

    & .gsc-results .gsc-cursor-box .gsc-cursor-page.gsc-cursor-current-page {
      border-radius: 12px;
    }

    & .gcsc-more-maybe-branding-root {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 48px;
    }

    & .gcsc-find-more-on-google {
      margin: 0;

      & > svg {
        width: 15px;
        height: 15px;
      }
    }

    & .gcsc-find-more-on-google-branding {
      flex-direction: row;
      align-items: center;
      margin: 0;
    }

    & .gcsc-branding {
      margin-top: 0;
      padding: 0;
    }

    & .gcsc-branding-img-noclear {
      margin-top: 5px;
    }
  }

}
